class FieldMappingReadDto {
    IsKey: boolean;
    SourceFieldReferenceId: number;
    TargetFieldReferenceId: number;
    LinkedFieldReferenceId: number | null;

    constructor(
        isKey: boolean,
        sourceFieldReferenceId: number,
        targetFieldReferenceId: number,
        linkedFieldReferenceId: number | null
    ) {
        this.IsKey = isKey;
        this.SourceFieldReferenceId = sourceFieldReferenceId;
        this.TargetFieldReferenceId = targetFieldReferenceId;
        this.LinkedFieldReferenceId = linkedFieldReferenceId;
    }
}

export default FieldMappingReadDto;
