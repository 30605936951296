import ScheduleDto from "../../../dtos/schedulermodule/ScheduleDto";
import TableMappingReadDto from "../../../dtos/syncmodule/TableMappingReadDto";
import FieldMappingReadDto from "../../../dtos/syncmodule/FieldMappingReadDto";

class GetPathSynchroDetailResult {
    Id: number;
    Name: string;
    Source: TableMappingReadDto | null;
    Target: TableMappingReadDto | null;
    Mappings: FieldMappingReadDto[];
    Schedule: ScheduleDto;

    constructor(id: number, name: string, source: TableMappingReadDto | null, target: TableMappingReadDto | null, mappings: FieldMappingReadDto[], schedule: ScheduleDto) {
        this.Id = id;
        this.Name = name;
        this.Source = source;
        this.Target = target;
        this.Mappings = mappings;
        this.Schedule = schedule;
    }
}

export default GetPathSynchroDetailResult;
