import React from 'react';
import './explore.scss';

export default function Explore() {
    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '600px' }}>
                <img src="svgs/underconstruction.svg" alt="underconstruction" width="300px" />
            </div>
        </React.Fragment>
)}