import React, { useState, useEffect, useCallback } from 'react';
import Tabs, { Item } from 'devextreme-react/tabs';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'devextreme-react';

import ChangeInProgressForm from '../../components/change-in-progress-form/ChangeInProgressForm';

import SourceSelection from '../../components/source-selection/SourceSelection';
import ReportingExposedFields from '../../components/reporting-exposed-fields/ReportingExposedFields';
import ReportingResume from '../../components/reporting-resume/ReportingResume';

import PathReportingApi from '../../api/PathReportingApi';

import GetPathReportingDetailResult from '../../classes/api/result/pathreportingmodule/GetPathReportingDetailResult';

import TableMappingReadDto from '../../classes/dtos/pathcommonmodule/TableMappingReadDto';
import FieldMappingDto from '../../classes/dtos/pathreportingmodule/FieldMappingDto';

import './path-reporting.scss';

export default function PathReporting() {

    const { id } = useParams();

    const navigate = useNavigate();

    const pathId = Number.parseInt(id ?? "0");

    const [pathReportingDetail, setPathReportingDetail] = useState<GetPathReportingDetailResult | null>(null);

    const [currentTab, setCurrentTab] = useState<number>(0);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [changeInprogress, setChangeInprogress] = useState<boolean>(false);

    useEffect(() => {
        PathReportingApi.GetPathReportingDetailAsync(pathId)
            .then(detail => {
                setPathReportingDetail(detail.Result as GetPathReportingDetailResult);
            });
    }, [pathId]);

    const handleTableMappingChange = useCallback((tableMappingReadDto: TableMappingReadDto) => {
        setPathReportingDetail((previous) => {
            if (previous === null) {
                return previous;
            }

            return new GetPathReportingDetailResult(
                previous.Id,
                previous.Name,
                tableMappingReadDto,
                previous.Mappings,
                previous.Schedule);
        });

    }, []);

    const handleMappingsChange = useCallback((fieldMappingDtos: FieldMappingDto[]) => {

        setPathReportingDetail((previous) => {
            if (previous === null) {
                return previous;
            }

            setChangeInprogress(true);

            return new GetPathReportingDetailResult(
                previous.Id,
                previous.Name,
                previous.Source,
                fieldMappingDtos,
                previous.Schedule);
        });

    }, []);

    function save(publish: boolean): void {
        if (pathReportingDetail === null || pathReportingDetail.Source === null) {
            alert("ERROR");
            return;
        }

        PathReportingApi.UpdatePathReportingDetailAsync(
            pathId,
            pathReportingDetail.Name,
            pathReportingDetail.Mappings,
            pathReportingDetail.Source.TableReferenceId,
            pathReportingDetail.Schedule,
            publish)
            .then((updateResult) => { if (updateResult.IsFailed()) { alert("ERROR"); } });

        setChangeInprogress(false);
    }

    function cancel(): void {

        if (changeInprogress) {
            setIsOpen(true);
        }
        else {
            navigate("/paths");
        }
    }

    function cancelFromModal(): void {

        setIsOpen(false);
        navigate("/paths");
    }

    function saveDraftFromModal(): void {

        setIsOpen(false);
        save(false);
        navigate("/paths");
    }


    function changePage(index: number): void {
        setCurrentTab(index);
    };

    function back() {
        setCurrentTab(currentTab - 1);
    }

    function next() {
       setCurrentTab(currentTab + 1);
    }

    if (pathReportingDetail === null) {
        return (<div>Waiting</div>);
    }

    return (
        <React.Fragment>
            <div style={{ width: '100%', height: '100%', padding: 24, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex' }}>
                <div style={{ alignSelf: 'stretch', height: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'flex' }}>
                    <div style={{ alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'center', display: 'inline-flex' }}>
                        <div style={{ justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex' }}>
                            <div style={{ color: '#E3E3E8', fontSize: 24, fontFamily: 'Manrope', fontWeight: '600', lineHeight: '36px', letterSpacing: 0.48, wordWrap: 'break-word' }}>{pathReportingDetail?.Name ?? "???"}</div>
                        </div>
                        <Button text="Cancel" type="normal" stylingMode="text" onClick={() => cancel()} />
                    </div>
                    <Tabs width='100%' stylingMode='secondary' onSelectedIndexChange={changePage} selectedIndex={currentTab} >
                        <Item text="Source">
                        </Item>
                        <Item text="Exposed field(s)">
                        </Item>
                        <Item text="Checking">
                        </Item>
                    </Tabs>
                    {(currentTab === 0) ? <SourceSelection TableMapping={pathReportingDetail.Source ?? null} SetTableMapping={handleTableMappingChange} /> : ""}
                    {(currentTab === 1) ? <ReportingExposedFields Source={pathReportingDetail.Source ?? null} Mappings={pathReportingDetail?.Mappings ?? []} SetMappings={handleMappingsChange} /> : ""}
                    {(currentTab === 2) ? <ReportingResume Source={pathReportingDetail.Source ?? null} Mappings={pathReportingDetail?.Mappings ?? []} /> : ""}
                </div>
                <div style={{ width: '100%', height: 64, position: 'absolute', bottom: 64, left: 0, display: 'flex', justifyContent: 'space-between', padding: '16px 24px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button icon='back' text="Back to previous step" type="normal" stylingMode="text" onClick={() => back()} disabled={currentTab === 0} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
                        <Button text="Save as draft" type="normal" stylingMode="text" onClick={() => save(false)} />
                        {currentTab === 2 ?
                            <Button text="Save and publish" type="default" stylingMode="contained" onClick={() => save(true)} /> :
                            <Button text="Continue to next step" type="default" stylingMode="contained" onClick={() => next()} />}
                    </div>
                </div>
            </div>
            <ChangeInProgressForm IsOpen={isOpen} SetIsOpen={setIsOpen} Ok={() => saveDraftFromModal()} Cancel={() => cancelFromModal()} />
        </React.Fragment>
    )
}
