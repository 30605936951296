import React, { useCallback } from 'react';

import TableMappingReadDto from '../../classes/dtos/pathreportingmodule/TableMappingReadDto';
import FieldMappingDto from '../../classes/dtos/pathreportingmodule/FieldMappingDto';

import FieldGrid from './FieldGrid';

import './ReportingExposedFields.css';

interface ReportingExposedFieldsProps {
    Source: TableMappingReadDto | null;
    Mappings: FieldMappingDto[];
    SetMappings(value: FieldMappingDto[]): void;
}

const ReportingExposedFields: React.FC<ReportingExposedFieldsProps> = ({ Source, Mappings, SetMappings }) => {

    //const handleDataReportingExposedFields = useCallback((dataReportingExposedFieldsDto: DataReportingExposedFieldsDto) => {
    //    if (TableMapping?.DataSourceId !== dataReportingExposedFieldsDto.DataSource?.Id) {
    //        var newTableMapping = new TableMappingReadDto(
    //            dataReportingExposedFieldsDto.DataSource?.Id,
    //            dataReportingExposedFieldsDto.DataSource?.Name, 0, '', 0, '');
    //        SetTableMapping(newTableMapping);
    //    }
    //}, []);

    return (
        <React.Fragment>
            <div style={{ width: '100%', height: 578, position: 'relative', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                <div style={{ top: 40, position: 'absolute', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                    <FieldGrid DataSourceId={Source?.DataSourceId ?? 0} TableReferenceId={Source?.TableReferenceId ?? 0} Mappings={Mappings} SetMappings={SetMappings} />
                </div>
            </div>
        </React.Fragment>
    );
}

export default ReportingExposedFields;