class FieldMappingDto {
    SourceFieldReferenceId: number;
    IsQueryable: boolean;
    IsRequired: boolean;
    DefaultValue: string | null;
    IsExposed: boolean;

    constructor(
        sourceFieldReferenceId: number,
        isQueryable: boolean,
        isRequired: boolean,
        defaultValue: string | null,
        isExposed: boolean
    ) {
        this.SourceFieldReferenceId = sourceFieldReferenceId;
        this.IsQueryable = isQueryable;
        this.IsRequired = isRequired;
        this.DefaultValue = defaultValue;
        this.IsExposed = isExposed;
    }
}

export default FieldMappingDto;
