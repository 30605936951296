import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from 'devextreme-react';

import PathApi from '../../api/PathApi';

import PathGrid from '../../components/path-grid/PathGrid';
import PathListDto from '../../classes/dtos/pathmodule/PathListDto';

import PathCreationForm from '../../components/path-creation-form/PathCreationForm';

import PathType from '../../consts/pathmodule/PathType';

import { PathSynchroRoute, PathReportingRoute } from '../../app-routes';

import './paths.scss';

export default function Paths(props: { openPath: (pathId: number, pathTypeId: number) => void }) {

    var navigate = useNavigate();

    function OpenPath(pathId: number, pathTypeId: number) {
        switch (pathTypeId) {
            case PathType.DataSynchronization:
                navigate("/" + PathSynchroRoute + "/" + pathId);
                break;
            case PathType.Reporting:
                navigate("/" + PathReportingRoute + "/" + pathId);
                break;
            default:
        }
    }

    const [creationInProgress, setCreationInProgress] = useState(false);

    // States
    const [paths, setPaths] = useState<Array<PathListDto> | null>(null);
    //const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const [listToRefresh, setListToRefresh] = useState<boolean>(false);

    // Fetch paths from API
    const refresh = useCallback(() => {
        if (!paths || listToRefresh) {
            PathApi.GetPathsAsync().then((allPaths: any) => {
                if (allPaths.IsFailed()) {
                    // TODO: Handle failure case (e.g., logout)
                } else {
                    setPaths(allPaths.Result);
                    setListToRefresh(false);
                }
            });
        }
    }, [paths, listToRefresh]);

    // UseEffect to refresh when component mounts
    useEffect(() => {
        refresh();
    }, [refresh]);

    //// Function to open dialog
    //const openDialog = () => {
    //    setDialogIsOpen(true);
    //};

    //// Function to close dialog
    //const closeDialog = (pathId: number | null, pathTypeId: number | null) => {
    //    setDialogIsOpen(false);
    //    setListToRefresh(false);
    //    if (pathId !== null && pathTypeId !== null) {
    //        props.openPath(pathId, pathTypeId);
    //    }
    //};

    // Function to open a specific path
    const openPath = (pathId: number, pathTypeId: number) => {
        OpenPath(pathId, pathTypeId);
    };

    const createPath = () => {
        setCreationInProgress(true);
    };

    return (
        <React.Fragment>
            <div className={"Frame_18_2093_2751"}>
                <div className={"Frame_34_2093_2752"}>
                    <h2>{"All your paths"}</h2>
                    <Button icon="plus" width={140} text="Create path" type="default" stylingMode="contained" onClick={createPath} />
                </div>
                <PathGrid paths={paths} openPath={openPath} />
            </div>
            <PathCreationForm IsOpen={creationInProgress} SetIsOpen={setCreationInProgress} Created={OpenPath} />
        </React.Fragment >
    );
}
