import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import type { AuthContextType } from '../types';

import { useMsal } from '@azure/msal-react';
import UserSession from '../api/UserSession';

function AuthProvider(props: React.PropsWithChildren<unknown>) {
    const [user, setUser] = useState<UserData>();
    const [loading, setLoading] = useState(true);
    const { instance } = useMsal();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        instance.handleRedirectPromise().then((response) => {
            if (response) {
                if (location.pathname === "/") {
                    navigate("/dashboard");
                }
            }
        }).catch(error => {
            console.error(error);
        });
    }, [instance, navigate]);

    useEffect(() => {
        (async function () {
            const result = await UserSession.EnsureUserDataAsync();
            setUser(result);

            setLoading(false);
        })();
    }, []);

    const signOut = useCallback(() => {
        instance.logoutRedirect();
    }, [instance]);


    return (
        <AuthContext.Provider value={{ user, signOut, loading }} {...props} />
    );
}

const AuthContext = createContext<AuthContextType>({ loading: false } as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
