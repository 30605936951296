import React, { useCallback } from 'react';
import { DataGrid, Selection, Column, Paging, FilterRow } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import GlobalLoader from '../loader/GlobalLoader';
import PathListDto from '../../classes/dtos/pathmodule/PathListDto';

import './PathGrid.css';

interface PathGridProps {
    paths: Array<PathListDto> | null;
    openPath: (id: number, typeId: number) => void;
}

// Define the event type for selection change
interface SelectionChangedEvent {
    selectedRowsData: PathListDto[];
}

const PathGrid: React.FC<PathGridProps> = (props) => {
    // Callback function when selection changes
    const onSelectionChanged = useCallback(
        ({ selectedRowsData }: SelectionChangedEvent) => {
            if (selectedRowsData.length > 0) {
                props.openPath(selectedRowsData[0].Id, selectedRowsData[0].TypeId);
            }
        },
        [props]
    );

    // Create a custom data source using CustomStore
    const customDataSource = new CustomStore({
        key: 'Id',
        load: () => {
            return props.paths || [];
        },
    });

    if (!props.paths) {
        return <GlobalLoader />
    }

    return (
        <div className={"BlockCard-PathGrid"}>
            <DataGrid
                id="dataGrid"
                dataSource={customDataSource}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                hoverStateEnabled={true}
                onSelectionChanged={onSelectionChanged}
            >
                <Paging defaultPageSize={10} />
                <Selection mode="single" />
                <FilterRow visible={true} />
                {/*<SearchPanel visible={true} />*/}
                <Column caption="NAME" dataField="Name" dataType="string" />
                <Column caption="TYPE" dataField="TypeLabel" dataType="string" />
                <Column caption="CREATED BY" dataField="CreatedBy" dataType="string" />
                <Column caption="CREATION" dataField="CreationDate" dataType="datetime" />
                <Column caption="LAST UPDATE" dataField="UpdateDate" dataType="datetime" />
                <Column caption="CURRENT" dataField="CurrentPeriodRuns" dataType="number" />
                <Column caption="ALL" dataField="AllPeriodsRuns" dataType="number" />
            </DataGrid>
        </div>
    );
};

export default PathGrid;
