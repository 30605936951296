import React, { useState, useCallback } from 'react';

import DatabaseSelectionDto from '../../classes/dtos/datastructuremodule/DatabaseSelectionDto';

import DatabaseSelectionForm from '../database-selection/DatabaseSelectionForm';

interface DatabaseSelectionProps {
    DataSourceId: number;
    DatabaseReferenceId: number;
    DatabaseReferenceLabel: string;
    SetDatabaseSelected(databaseSelectionDto: DatabaseSelectionDto): void;
}

const DatabaseSelection: React.FC<DatabaseSelectionProps> = ({ DataSourceId, DatabaseReferenceId, DatabaseReferenceLabel, SetDatabaseSelected }) => {

    const [selectionInProgress, setSelectionInProgress] = useState(false);

    const handleSetDatabaseSelected = (databaseSelectionDto: DatabaseSelectionDto) => {
        SetDatabaseSelected(databaseSelectionDto);
    };

    function selectDatabase(): void {
        setSelectionInProgress(true);
    };

    return (
        <React.Fragment>

            {DataSourceId === 0 ?
                (<div style={{ padding: '20px', opacity: 0.30, background: '#1A1A20', borderRadius: 16, justifyContent: 'flex-start', alignItems: 'center', gap: 32, display: 'inline-flex' }}>
                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
                        <div style={{ alignSelf: 'stretch', color: '#E3E3E8', fontSize: 16, fontFamily: 'Manrope', fontWeight: '600', lineHeight: '24px', letterSpacing: 0.32, wordWrap: 'break-word' }}>2. Choose your database</div>
                        <div style={{ color: '#9393A2', fontSize: 12, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '15.60px', letterSpacing: 0.24, wordWrap: 'break-word' }}>Lorem ipsum dolor sit amet consectetur. Ut metus sed<br />quisque rhoncus at augue ultrices.</div>
                    </div>
                    <img src="svgs/plussource.svg" alt="plus"></img>
                </div>) :
                (<div style={{ width: 440, padding: '20px', background: '#1A1A20', borderRadius: 16, border: '1px #3D3D46 solid', justifyContent: 'flex-start', alignItems: 'center', gap: '32px', display: 'inline-flex' }}>
                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                            <div style={{ color: '#E3E3E8', fontSize: 16, fontFamily: 'Manrope', fontWeight: '600', lineHeight: '24px', letterSpacing: 0.32, wordWrap: 'break-word' }}>2. Choose your database</div>
                            {DatabaseReferenceId > 0 && <img src="svgs/circle.svg" alt="circle" />}
                        </div>
                        <div style={{ color: '#9393A2', fontSize: 12, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '15.60px', letterSpacing: 0.24, wordWrap: 'break-word', alignSelf: 'flex-start' }}>
                            {DatabaseReferenceId > 0 ? DatabaseReferenceLabel + " selected" : "No database selected"}
                        </div>
                    </div>
                    {DatabaseReferenceId === 0 ?
                        <img src="svgs/plussource.svg" alt="plus" style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={selectDatabase} /> :
                        <div style={{ marginLeft: 'auto', cursor: 'pointer', gap: 6, display: 'flex' }} onClick={selectDatabase} >
                            <div style={{ color: '#E3E3E8', fontSize: 14, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '21px', letterSpacing: 0.28, wordWrap: 'break-word' }}>Edit</div>
                            <img src="svgs/edit.svg" alt="circle" />
                        </div>}
                </div>)}
            <DatabaseSelectionForm DataSourceId={DataSourceId} IsOpen={selectionInProgress} SetIsOpen={setSelectionInProgress} SetDatabaseSelected={handleSetDatabaseSelected} />
        </React.Fragment>
    );
}

export default DatabaseSelection;