import React, { useState, useEffect, useCallback } from 'react';
import Tabs, { Item } from 'devextreme-react/tabs';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'devextreme-react';

import ChangeInProgressForm from '../../components/change-in-progress-form/ChangeInProgressForm';

import SourceSelection from '../../components/source-selection/SourceSelection';



import PathSynchroApi from '../../api/PathSynchroApi';

import GetPathSynchroDetailResult from '../../classes/api/result/pathsynchromodule/GetPathSynchroDetailResult';

import TableMappingReadDto from '../../classes/dtos/pathcommonmodule/TableMappingReadDto';


import './path-synchro.scss';

export default function PathSynchro() {

    const { id } = useParams();

    const navigate = useNavigate();

    const pathId = Number.parseInt(id ?? "0");

    const [pathSynchroDetail, setPathSynchroDetail] = useState<GetPathSynchroDetailResult | null>(null);

    const [currentTab, setCurrentTab] = useState<number>(0);

    const [isOpen, setIsOpen] = useState<boolean>(false);



    useEffect(() => {
        PathSynchroApi.GetPathSynchroDetailAsync(pathId)
            .then(detail => {
                setPathSynchroDetail(detail.Result as GetPathSynchroDetailResult);
            });
    }, [pathId]);

    const handleTableMappingChange = useCallback((tableMappingReadDto: TableMappingReadDto) => {
        //setPathReportingDetail((previous) => {
        //    if (previous === null) {
        //        return previous;
        //    }

        //    return new GetPathReportingDetailResult(
        //        previous.Id,
        //        previous.Name,
        //        tableMappingReadDto,
        //        previous.Mappings,
        //        previous.Schedule);
        //});

    }, []);






    function cancel(): void {

        if (changeInprogress) {
            setIsOpen(true);
        }
        else {
            navigate("/paths");
        }
    }

    function cancelFromModal(): void {

        setIsOpen(false);
        navigate("/paths");
    }

    function saveDraftFromModal(): void {

        setIsOpen(false);
        save(false);
        navigate("/paths");
    }

    function changePage(index: number): void {
        setCurrentTab(index);
    };

    function back() {
        setCurrentTab(currentTab - 1);
    }

    function next() {
        setCurrentTab(currentTab + 1);
    }

    if (pathSynchroDetail === null) {
        return (<div>Waiting</div>);
    }

    return (
        <React.Fragment>
            <div style={{ width: '100%', height: '100%', padding: 24, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex' }}>
                <div style={{ alignSelf: 'stretch', height: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'flex' }}>
                    <div style={{ alignSelf: 'stretch', justifyContent: 'space-between', alignItems: 'center', display: 'inline-flex' }}>
                        <div style={{ justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'flex' }}>
                            <div style={{ color: '#E3E3E8', fontSize: 24, fontFamily: 'Manrope', fontWeight: '600', lineHeight: '36px', letterSpacing: 0.48, wordWrap: 'break-word' }}>{pathSynchroDetail?.Name ?? "???"}</div>
                        </div>
                        <Button text="Cancel" type="normal" stylingMode="text" onClick={() => cancel()} />
                    </div>
                    <Tabs width='100%' stylingMode='secondary' onSelectedIndexChange={changePage} selectedIndex={currentTab} >
                        <Item text="Source">
                        </Item>
                        <Item text="Target">
                        </Item>
                        <Item text="Mapping">
                        </Item>
                        <Item text="Schedule">
                        </Item>
                        <Item text="Checking">
                        </Item>
                    </Tabs>
                    {(currentTab === 0) ? <SourceSelection TableMapping={pathSynchroDetail.Source ?? null} SetTableMapping={handleTableMappingChange} /> : ""}
                    {(currentTab === 1) ? <SourceSelection TableMapping={pathSynchroDetail.Target ?? null} SetTableMapping={handleTableMappingChange} /> : ""}
                </div>
                <div style={{ width: '100%', height: 64, position: 'absolute', bottom: 64, left: 0, display: 'flex', justifyContent: 'space-between', padding: '16px 24px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button icon='back' text="Back to previous step" type="normal" stylingMode="text" onClick={() => back()} disabled={currentTab === 0} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
                        <Button text="Save as draft" type="normal" stylingMode="text" onClick={() => save(false)} />
                        {currentTab === 2 ?
                            <Button text="Save and publish" type="default" stylingMode="contained" onClick={() => save(true)} /> :
                            <Button text="Continue to next step" type="default" stylingMode="contained" onClick={() => next()} />}
                    </div>
                </div>
            </div>
            <ChangeInProgressForm IsOpen={isOpen} SetIsOpen={setIsOpen} Ok={() => saveDraftFromModal()} Cancel={() => cancelFromModal()} />
        </React.Fragment>
    )
}
