class FieldTypeReferenceDto {
    Type: number;  // Assuming Type is a number representing some kind of field type identifier
    TypeLabel: string;
    IsNullable: boolean;
    MaxLength: number | null;  // MaxLength can be null if there's no maximum length specified
    OriginTypeLabel: string;
    LinkTableId: number | null;  // LinkTableId can be null if there's no linked table

    constructor(
        type: number,
        typeLabel: string,
        isNullable: boolean,
        maxLength: number | null,
        originTypeLabel: string,
        linkTableId: number | null
    ) {
        this.Type = type;
        this.TypeLabel = typeLabel;
        this.IsNullable = isNullable;
        this.MaxLength = maxLength;
        this.OriginTypeLabel = originTypeLabel;
        this.LinkTableId = linkTableId;
    }
}

export default FieldTypeReferenceDto;
