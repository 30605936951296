import React, { useCallback } from 'react';

import TableMappingReadDto from '../../classes/dtos/pathcommonmodule/TableMappingReadDto';

import DataSourceSelectionDto from '../../classes/dtos/datasourcemodule/DataSourceSelectionDto';
import DatabaseSelectionDto from '../../classes/dtos/datastructuremodule/DatabaseSelectionDto';
import TableSelectionDto from '../../classes/dtos/datastructuremodule/TableSelectionDto';

import DataSourceSelection from './DataSourceSelection';
import DatabaseSelection from './DatabaseSelection';
import TableSelection from './TableSelection';

import './SourceSelection.css';

interface SourceSelectionProps {
    TableMapping: TableMappingReadDto | null;
    SetTableMapping(value: TableMappingReadDto): void;
}

const SourceSelection: React.FC<SourceSelectionProps> = ({ TableMapping, SetTableMapping }) => {

    const handleSetDataSourceSelected = useCallback((dataSourceSelectionDto: DataSourceSelectionDto) => {

        if (TableMapping !== null && dataSourceSelectionDto.DataSource !== null) {

            if (TableMapping.DataSourceId !== dataSourceSelectionDto.DataSource.Id) {

                var newTableMapping = new TableMappingReadDto(
                    dataSourceSelectionDto.DataSource.Id,
                    dataSourceSelectionDto.DataSource.Name,
                    0,
                    '',
                    0,
                    '');

                SetTableMapping(newTableMapping);
            }
        }

    }, [SetTableMapping, TableMapping]);

    const handleSetDatabaseSelected = useCallback((databaseSelectionDto: DatabaseSelectionDto) => {

        if (TableMapping !== null && databaseSelectionDto.Database !== null) {

            if (TableMapping.DatabaseReferenceId !== databaseSelectionDto.Database.Id) {

                var newTableMapping = new TableMappingReadDto(
                    TableMapping.DataSourceId,
                    TableMapping.DataSourceLabel,
                    databaseSelectionDto.Database.Id,
                    databaseSelectionDto.Database.Name,
                    0,
                    '');

                SetTableMapping(newTableMapping);
            }
        }

    }, [SetTableMapping, TableMapping]);

    const handleSetTableSelected = useCallback((tableSelectionDto: TableSelectionDto) => {

        if (TableMapping !== null && tableSelectionDto.Table !== null) {

            if (TableMapping.TableReferenceId !== tableSelectionDto.Table.Id) {

                var newTableMapping = new TableMappingReadDto(
                    TableMapping.DataSourceId,
                    TableMapping.DataSourceLabel,
                    TableMapping.DatabaseReferenceId,
                    TableMapping.DatabaseReferenceLabel,
                    tableSelectionDto.Table.Id,
                    tableSelectionDto.Table.Name);

                SetTableMapping(newTableMapping);
            }
        }

    }, [SetTableMapping, TableMapping]);

    return (
        <React.Fragment>
            <div style={{ width: '100%', height: 578, position: 'relative', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                <div style={{ top: 40, position: 'absolute', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                    <DataSourceSelection DataSourceId={TableMapping?.DataSourceId ?? 0} DataSourceLabel={TableMapping?.DataSourceLabel ?? "?????"} SetDataSourceSelected={handleSetDataSourceSelected}/>

                    {/*<div style={{ width: 440, padding: '20px', background: '#1A1A20', borderRadius: 16, border: '1px #3D3D46 solid', justifyContent: 'flex-start', alignItems: 'center', gap: '32px', display: 'inline-flex' }} onClick={createPath}>*/}
                    {/*    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 12, display: 'inline-flex' }}>*/}
                    {/*        <div style={{ alignSelf: 'stretch', color: '#E3E3E8', fontSize: 16, fontFamily: 'Manrope', fontWeight: '600', lineHeight: '24px', letterSpacing: 0.32, wordWrap: 'break-word' }}>1. Select your source</div><img src="svgs/circle.svg" alt="circle"></img>*/}
                    {/*        <div style={{ color: '#9393A2', fontSize: 12, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '15.60px', letterSpacing: 0.24, wordWrap: 'break-word' }}>{TableMapping?.DataSourceId > 0 ? TableMapping?.DataSourceLabel+" selected" : (<div></div>)}</div>*/}
                    {/*    </div>*/}
                    {/*    <img src="svgs/plussource.svg" alt="plus" style={{ marginLeft: 'auto' }} />*/}
                    {/*</div>*/}



                    {/*<div style={{ width: 440, padding: '20px', background: '#1A1A20', borderRadius: 16, border: '1px #3D3D46 solid', justifyContent: 'flex-start', alignItems: 'center', gap: '32px', display: 'inline-flex' }} onClick={createPath}>*/}
                    {/*    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 12, display: 'inline-flex' }}>*/}
                    {/*        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>*/}
                    {/*            <div style={{ color: '#E3E3E8', fontSize: 16, fontFamily: 'Manrope', fontWeight: '600', lineHeight: '24px', letterSpacing: 0.32, wordWrap: 'break-word' }}>1. Select your source</div>*/}
                    {/*            <img src="svgs/circle.svg" alt="circle" />*/}
                    {/*        </div>*/}
                    {/*        <div style={{ color: '#9393A2', fontSize: 12, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '15.60px', letterSpacing: 0.24, wordWrap: 'break-word', alignSelf: 'flex-start' }}>*/}
                    {/*            {TableMapping?.DataSourceId > 0 ? TableMapping?.DataSourceLabel + " selected" : (<div></div>)}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <img src="svgs/plussource.svg" alt="plus" style={{ marginLeft: 'auto' }} />*/}
                    {/*</div>*/}

                    <div style={{ width: 1, height: 32, transformOrigin: '0 0', border: '1px #3D3D46 dotted' }}></div>
                    <DatabaseSelection DataSourceId={TableMapping?.DataSourceId ?? 0} DatabaseReferenceId={TableMapping?.DatabaseReferenceId ?? 0} DatabaseReferenceLabel={TableMapping?.DatabaseReferenceLabel ?? "?????"} SetDatabaseSelected={handleSetDatabaseSelected} />
                    <div style={{ width: 1, height: 32, transformOrigin: '0 0', border: '1px #3D3D46 dotted' }}></div>
                    <TableSelection DataSourceId={TableMapping?.DataSourceId ?? 0} DatabaseReferenceId={TableMapping?.DatabaseReferenceId ?? 0} TableReferenceId={TableMapping?.TableReferenceId ?? 0} TableReferenceLabel={TableMapping?.TableReferenceLabel ?? "?????"} SetTableSelected={handleSetTableSelected} />
                </div>
            </div>
        </React.Fragment>
    );
}

export default SourceSelection;